<template>
    <div id="app" class="IntelligentSecurity">
        <div class="fullScreen">
            <i class="icon-quanping" v-show="!fullScreen" @click="fullScreenClick" style="cursor: pointer;"></i>
            <i class="icon-tuichuquanping" v-show="fullScreen" @click="outFullScreenClick" style="cursor: pointer;"></i>
        </div>
        <div class="main">

            <div class="left">
                <div class="header">
                    <div class="dateTime">
                        <span>{{ currentTime }}</span>
                        <span>{{ currentDate }}</span>
                    </div>
                    <!-- 机构总数量 -->
                    <div class="statistics">
                        <div>
                            <img src="../../../assets/visualization/IntelligentSecurity/headerIcon1.png" alt="">
                        </div>
                        <div>
                            <span>在建项目/机构总数量</span>
                            <span>{{ formaterNumber(headerData.institutionsAmount) }} <span>个</span> </span>
                        </div>
                    </div>
                    <!-- 机构总人数 -->
                    <div class="statistics">
                        <div>
                            <img src="../../../assets/visualization/IntelligentSecurity/headerIcon2.png" alt="">
                        </div>
                        <div>
                            <span>在建项目/机构总人数</span>
                            <span>{{ formaterNumber(headerData.institutionsPersonnelAmount) }} <span>人</span> </span>
                        </div>
                    </div>
                    <!-- 专职安全环保人员 -->
                    <div class="statistics">
                        <div>
                            <img src="../../../assets/visualization/IntelligentSecurity/headerIcon3.png" alt="">
                        </div>
                        <div>
                            <span>专职安全环保人员</span>
                            <span>{{ formaterNumber(headerData.protectionPersonnel) }} <span>人</span> </span>
                        </div>
                    </div>
                </div>
                <div class="body leftBody">
                    <div class="left1 modelBody">
                        <div class="modelBody-title-L">在建项目、运营机构概况</div>
                        <div class="modelBody-body-L listBox-itemL leftTop">
                            <div>
                                <div>境内项目</div>
                                <div class="itemSL">
                                    <span>项目数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.domesticPojAmount) }}
                                    </span>
                                </div>
                                <div class="itemSL">
                                    <span>人员数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.domesticPersonnelAmount) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div>境外项目-机构</div>
                                <div class="itemLL itemLLDB">
                                    <div>
                                        <span style="color: #E3F3FF;">项目/机构数</span>
                                        <span style="color: #00aaff;">
                                            {{ formaterNumber(InstitutionalOverview.abroadPojAmount) }}
                                        </span>
                                    </div>
                                    <!-- <span>|</span>
                                    <div>
                                        <span style="color: #E3F3FF;">风险地区项目占比数</span>
                                        <span style="color: #fca400;">341</span>
                                    </div> -->
                                </div>
                                <div class="itemLL itemLLDB">
                                    <div>
                                        <span style="color: #E3F3FF;">人员数</span>
                                        <span style="color: #00aaff;">
                                            {{ formaterNumber(InstitutionalOverview.abroadpersonnelAmount) }}
                                        </span>
                                    </div>
                                    <!-- <span>|</span>
                                    <div>
                                        <span style="color: #E3F3FF;">风险地区项目占比数</span>
                                        <span style="color: #fca400;">341</span>
                                    </div> -->
                                </div>
                            </div>
                            <div>
                                <div>工厂</div>
                                <div class="itemSL">
                                    <span>工厂数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.factoryAmount) }}
                                    </span>
                                </div>
                                <div class="itemSL">
                                    <span>人员数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.factoryPersonnelAmount) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left2 modelBody">
                        <div class="modelBody-title-L" style="display: flex;">
                            <span style="flex: 1;">项目机构分布情况</span>
                            <span style="flex: 1;">境外项目中方外籍人员占比数</span>
                        </div>
                        <div class="modelBody-body-L listBox-itemL tableBox">
                            <div class="tableBoxMain">
                                <div class="tableHeader">
                                    <span>境外国家</span>
                                    <span>项目/机构数</span>
                                    <span>人数</span>
                                </div>
                                <div class="tableBodyFather">
                                    <div class="tableBody" id="tableBodyLeft">
                                        <div class="tableTh" v-for="(item, index) in projectTableLeft">
                                            <span>
                                                <span class="indexIcon">{{ index + 1 }}</span>
                                                {{ item.orgName }}
                                            </span>
                                            <span>{{ item.value }}</span>
                                            <span>{{ item.value2 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tableBoxMain" id="echartsL0"> </div>
                        </div>
                    </div>
                    <div class="left3 modelBody">
                        <div class="modelBody-title-S">境外风险项目数占比统计</div>
                        <div class="modelBody-body-S" id="echartsL1"></div>
                    </div>
                    <div class="left4 modelBody">
                        <div class="modelBody-title-S">境外风险项目人数占比统计</div>
                        <div class="modelBody-body-S" id="echartsL2"></div>
                    </div>
                    <div class="left5 modelBody">
                        <div class="modelBody-title-S">“零违章”评选情况</div>
                        <div class="modelBody-body-S listBox-itemL">
                            <div class="itemSL">
                                <span>个人数</span>
                                <span style="color: #00aaff;">
                                    {{ formaterNumber(ViolationOfRegulations.personnelAmount) }}
                                </span>
                            </div>
                            <div class="itemSL">
                                <span>班组数</span>
                                <span style="color: #00aaff;">
                                    {{ formaterNumber(ViolationOfRegulations.classAmount) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="left6 modelBody">
                        <div class="modelBody-title-S">年度应急演练总次数</div>
                        <div class="modelBody-body-S listBox-itemL">
                            <div class="itemSL">
                                <span>境外应急演练次数</span>
                                <span style="color: #00aaff;">
                                    {{ formaterNumber(drillNum.domestic) }}
                                </span>
                            </div>
                            <div class="itemSL">
                                <span>境内应急演练次数</span>
                                <span style="color: #00aaff;">
                                    {{ formaterNumber(drillNum.abroad) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="earthMap center">
                <div v-show="activeWordMap" id="earthMap"></div>
                <div v-show="!activeWordMap" id="wordMap"></div>
                <div class="wordMapBox">
                    <div :class="{ 'activeWordMap': activeWordMap, 'wordMap': !activeWordMap }"
                        @click="activeWordMap = true">3D地图</div>
                    <div :class="{ 'activeWordMap': !activeWordMap, 'wordMap': activeWordMap }"
                        @click="activeWordMap = false">世界地图</div>
                </div>
                <loading :text="'正在加载地图'" :loadingState="loadingState"></loading>
            </div>
            <div class="right">
                <div class="header" style="justify-content: flex-start;">
                    <!-- 安全环保总投入 -->
                    <div class="statistics" style="margin-left: 0;">
                        <div>
                            <img src="../../../assets/visualization/IntelligentSecurity/headerIcon4.png" alt="">
                        </div>
                        <div>
                            <span>安全环保总投入</span>
                            <span>{{ formaterNumber(headerData.protectionInvolvement) }} <span>万元</span> </span>
                        </div>
                    </div>
                    <!-- 安全生产工时 -->
                    <div class="statistics">
                        <div>
                            <img src="../../../assets/visualization/IntelligentSecurity/headerIcon5.png" alt="">
                        </div>
                        <div>
                            <span>安全生产工时</span>
                            <span>{{ formaterNumber(headerData.safeHours) }} <span>小时</span> </span>
                        </div>
                    </div>
                    <div class="switchProject">
                        <avue-input-tree default-expand-all v-model="deptId" placeholder="请选择所属部门" @change="chooseDept"
                            :clearable="false" :props="inputTreeProps" :dic="nowUserDeptList"></avue-input-tree>
                    </div>
                </div>
                <div class="body rightBody">
                    <div class="modelBody">
                        <div class="modelBody-title-S">安全培训覆盖率</div>
                        <div class="modelBody-body-S circleChartL">
                            <div id="echartsRT1"></div>
                            <div>
                                <div>培训次数</div>
                                <div>参与培训人次</div>
                                <div>{{ formaterNumber(safetyRate.trainingAmount) }}</div>
                                <div>{{ formaterNumber(safetyRate.trainingPersonnel) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">培训次数统计</div>
                        <div class="modelBody-body-S" id="echartsRT2"></div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">本月公司培训次数</div>
                        <div class="modelBody-body-S" id="echartsRT3"></div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">年度安全检查统计</div>
                        <div class="modelBody-body-S" id="echartsRT4"></div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">应急预案建设率</div>
                        <div class="modelBody-body-S" id="echartsRB1"></div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">千人重伤率</div>
                        <div class="modelBody-body-S" id="echartsRB2"></div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">总体隐患整改率</div>
                        <div class="modelBody-body-S circleChartL">
                            <div id="echartsRB3"></div>
                            <div>
                                <div>培训总数</div>
                                <div>已整改隐患</div>
                                <div>{{ formaterNumber(dangerRate.dangerAmount) }}</div>
                                <div>{{ formaterNumber(dangerRate.rectified) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="modelBody">
                        <div class="modelBody-title-S">公司隐患整改率</div>
                        <div class="modelBody-body-S" id="echartsRB4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import loading from '../components/loading.vue';
import * as echarts from 'echarts';
import { visualization, worldData, dangerCountry } from '../../../api/visualization/index'
import world from './world.json'
import { mapGetters } from "vuex";
import { fullscreenToggel, listenfullscreen } from "@/util/util";
import {
    nowUserDept
} from "@/api/user";
export default {
    name: "IntelligentSecurity",
    components: {
        loading
    },
    data() {
        return {
            fullScreen: false,
            currentTime: '',
            currentDate: '',
            activeWordMap: true,
            wordShowType: true,
            nowYear: new Date().getFullYear(),
            nowMonth: new Date().getMonth() + 1,
            fontSize: window.innerWidth / 100,
            headerData: {
                institutionsAmount: 0, // 在建项目/机构总数量
                institutionsPersonnelAmount: 0, // 在建项目/机构总人数
                protectionPersonnel: 0, // 专职安全环保人员
                protectionInvolvement: 0, // 安全环保总投入
                safeHours: 0 // 安全生产工时
            },
            InstitutionalOverview: {
                domesticPojAmount: 0, // 境内项目数
                domesticPersonnelAmount: 0, // 境内人员数
                factoryAmount: 0, // 工厂数
                factoryPersonnelAmount: 0, // 工厂人员数
                abroadPojAmount: 0, // 项目/机构数
                abroadpersonnelAmount: 0 // 境外人员数
            },
            projectTableLeft: [],
            earthData: [],
            ViolationOfRegulations: {
                personnelAmount: 0,
                classAmount: 0
            },
            drillNum: {
                domestic: 0, // 境内
                abroad: 0   // 境外
            },
            safetyRate: {
                rate: 0, // 概率
                trainingAmount: 0, // 培训次数
                trainingPersonnel: 0 //参与培训人次
            },
            // 总体隐患整改率
            dangerRate: {
                rate: 0, // 概率
                dangerAmount: 0, // 隐患总数
                rectified: 0 // 已整改隐患
            },
            inputTreeProps: {
                label: "title",
                value: "id",
            },
            nowUserDeptList: [],
            deptId: ''
        }
    },
    created() {
        // 正式中材国际编码 1782377952097869826
        // 开发中材国际编码 1722789594114084866
        // 测试中材国际编码 1782377952097869826
        if (window.location.hostname == '123.60.160.227') {
            this.deptId = '1782377952097869826'
        } else if (window.location.hostname == '39.106.228.40') {
            this.deptId = '1782377952097869826'
        } else {
            this.deptId = '1722789594114084866'
        }
    },
    mounted() {
        echarts.registerMap('world', world);
        // 获取系统时间
        this.getTime();
        this.openTimer('tableBodyLeft');
        nowUserDept().then((res) => {
            if (res.data.code === 200) {
                this.nowUserDeptList = res.data.data;
            }
        });
    },
    computed: {
        ...mapGetters(["userInfo"])
    },
    watch: {
        activeWordMap(val) {
            if (!val && this.wordShowType) {
                this.wordShowType = false
                this.loadingState = true
                this.$nextTick(() => {
                    this.wordEcharts()
                })
            }
        }
    },
    methods: {
        fullScreenClick() {
            this.fullScreen = true
            fullscreenToggel()
        },
        outFullScreenClick() {
            this.fullScreen = false
            fullscreenToggel()
        },
        chooseDept(val) {
            console.log('切换项目', val);
            this.deptId = val
            this.getEcharts()
            // 在建项目/机构总数量

        },
        getHeaders() {
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0001_Y&indexCodes=ZB0002_Y&indexCodes=ZB0005_Y&indexCodes=ZB0004_Y&indexCodes=ZB0003_Y`
            visualization(params).then(res => {
                let data = res.data.data
                // console.log('头部数据', data);
                this.headerData.institutionsAmount = data.ZB0001_Y.length == 0 ? 0 : data.ZB0001_Y[0].value
                this.headerData.institutionsPersonnelAmount = data.ZB0002_Y.length == 0 ? 0 : data.ZB0002_Y[0].value
                this.headerData.protectionPersonnel = data.ZB0003_Y.length == 0 ? 0 : data.ZB0003_Y[0].value
                this.headerData.protectionInvolvement = data.ZB0005_Y.length == 0 ? 0 : data.ZB0005_Y[0].value
                this.headerData.safeHours = data.ZB0004_Y.length == 0 ? 0 : data.ZB0004_Y[0].value
            })
        },
        // 在建项目
        getInstitutionalOverview() {
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0016_Y&indexCodes=ZB0020_Y&indexCodes=ZB00111_Y&indexCodes=ZB0098_Y&indexCodes=ZB0014_Y&indexCodes=ZB0010_Y`
            visualization(params).then(res => {
                // console.log('测试新版接口', res.data.data);
                let data = res.data.data
                this.InstitutionalOverview.domesticPersonnelAmount = data.ZB0020_Y.length == 0 ? 0 : data.ZB0020_Y[0].value // 境内人员数
                this.InstitutionalOverview.abroadPojAmount = data.ZB0010_Y.length == 0 ? 0 : data.ZB0010_Y[0].value // 境外项目机构数量
                this.InstitutionalOverview.abroadpersonnelAmount = data.ZB0014_Y.length == 0 ? 0 : data.ZB0014_Y[0].value // 境外人员数
                this.InstitutionalOverview.domesticPojAmount = data.ZB0016_Y.length == 0 ? 0 : data.ZB0016_Y[0].value // 境内项目数
                this.InstitutionalOverview.factoryAmount = data.ZB0098_Y.length == 0 ? 0 : data.ZB0098_Y[0].value // 工厂总数
                this.InstitutionalOverview.factoryPersonnelAmount = data.ZB00111_Y.length == 0 ? 0 : data.ZB00111_Y[0].value // 工厂人员总数
            })
        },
        // 项目机构分布情况
        getProjectDistribution() {
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0063_Y`
            visualization(params).then(res => {
                // console.log('项目机构分布情况', res.data.data);
                this.projectTableLeft = res.data.data.ZB0063_Y
            })
        },
        // 获取世界地图相关数据
        getWorldData() {
            this.loadingState = true
            worldData({ orgId: this.deptId }).then(res => {
                // console.log('世界地图人员信息', res);
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        this.earthData.push({
                            name: item.prj_name,
                            value: [item.longitude, item.latitude],
                            info: {
                                chinesePersonnelNum: item.chinesePersonnelNum,
                                foreignNationalsNum: item.foreignNationalsNum
                            }
                        })

                    })
                    this.earthEcharts()
                    this.loadingState = false
                }
            })
        },
        // 添加千位符
        formaterNumber(num) {
            return new Intl.NumberFormat().format(num)
        },
        getEcharts() {
            this.getWorldData()
            this.earthEcharts()
            this.getEchartsL0()
            this.getEchartsL1()
            this.getEchartsL2()
            this.getEchartsRT1()
            this.getEchartsRT2()
            this.getEchartsRT3()
            this.getEchartsRT4()
            this.getEchartsRB1()
            this.getEchartsRB2()
            this.getEchartsRB3()
            this.getEchartsRB4()
            this.getViolationOfRegulations()
            this.getDrillNum()
            this.getHeaders()
            this.getInstitutionalOverview();
            this.getProjectDistribution();
        },
        getTime() {
            setInterval(() => {
                this.getDate();
            }, 1000);
        },
        getDate() {
            const now = new Date();
            this.currentDate = `${now.getFullYear()} ${now.getMonth() + 1} ${now.getDate()}`
            this.currentTime = `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}:${this.padZero(now.getSeconds())}`
        },
        padZero(num) {
            return num.toString().padStart(2, '0');
        },
        // 中间地球
        earthEcharts() {
            echarts.init(document.getElementById('earthMap')).dispose();
            let that = this
            let linesList = []
            this.earthData.forEach(item => {
                linesList.push([[116.475, 40.0109], item.value])
            })
            var myChart = echarts.init(document.getElementById('earthMap'));
            myChart.setOption({
                globe: {
                    baseTexture: require('../../../assets/visualization/IntelligentSecurity/earth.jpg'),
                    heightTexture: require('../../../assets/visualization/IntelligentSecurity/earthHB.jpg'),
                    displacementScale: 0.01,
                    shading: 'lambert',
                    realisticMaterial: {
                        metalness: 0.2, // 设置金属感
                        roughness: 0.2, // 设置粗糙度
                        reflectivity: 1, // 设置反射率
                    },
                    atmosphere: {
                        show: true,
                        color: "#377abd",
                        thickness: 0.3,
                        quality: 0.1,
                    },
                    postEffect: { // 后期特效
                        enable: false,
                        SSAO: {
                            enable: true,
                            radius: 2,
                            intensity: 1.5
                        },
                        depthOfField: {
                            enable: false,
                            focalDistance: 1000,
                            fstop: 1.8,
                            blurRadius: 1
                        },
                        bloom: {
                            enable: false,
                            threshold: 1,
                            blurSize: 1,
                            blurAmount: 0,
                            bloomStrength: 3,
                            bloomRadius: 0
                        },
                    },
                    light: {
                        main: {
                            intensity: 0.3,
                            shadow: true,
                            quality: 'high',
                        },
                        ambient: {
                            intensity: 0.8
                        }
                    },
                    viewControl: {
                        autoRotate: true,
                        alpha: 20,
                        beta: -5,
                        distance: 150,
                        autoRotateStart: [113.27, 23.13],
                    },
                },
            });
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            document.getElementById('earthMap').addEventListener('mouseenter', function () {
                myChart.setOption({
                    globe: {
                        viewControl: {
                            autoRotate: false // 鼠标悬浮时停止旋转
                        }
                    }
                });
            });

            document.getElementById('earthMap').addEventListener('mouseleave', function () {
                myChart.setOption({
                    globe: {
                        viewControl: {
                            autoRotate: true // 鼠标离开时恢复旋转
                        }
                    }
                });
            });
            // 地点信息
            myChart.setOption({
                series: [
                    {
                        type: 'scatter3D',
                        coordinateSystem: 'globe', // 'globe'：地球坐标系
                        showEffectOn: 'render', // 'render'：在渲染完成后显示特效。'emphasis'：在鼠标悬停时显示特效。'none'：不显示特效。
                        itemStyle: {
                            color: "#04e4bc",
                            opacity: 1
                            // borderColor: "red",
                            // borderWidth: 2,
                            // shadowColor: "red",
                            // shadowBlur: 10
                        },
                        // symbol: 'circle',
                        hoverAnimation: false,
                        symbolSize: [window.innerWidth / 100 * 1.2, window.innerWidth / 100 * 0.6],
                        data: that.earthData.map(function (d) {
                            return {
                                name: d.name,
                                value: [d.value[0], d.value[1], 0],
                                label: {
                                    show: false, // 默认不显示标签
                                    emphasis: { // 高亮状态的样式定义
                                        formatter: function (params) {
                                            // 根据需要展示的内容定制
                                            let content = '项目名称\n\n' + params.data.name; // 可以根据data里的属性定制内容
                                            if (params.data.info) { // 假设数据中还有其他属性info
                                                content += '\n\n中方人数:    ' + params.data.info.chinesePersonnelNum + '\n\n外籍人数:    ' + params.data.info.foreignNationalsNum;
                                            }
                                            return content;
                                        },
                                        position: 'top',
                                        show: true, // 鼠标悬浮时显示
                                        color: "#fff",
                                        backgroundColor: 'rgba(8, 66, 165, 0.8)',
                                        padding: 10, // 上右下左的内边距分别为 10px
                                        borderWidth: 1, // 边框宽度为 1 像素
                                        borderColor: '#34a0e9', // 红色边框
                                        borderRadius: 5,
                                    }
                                },
                                // 假设除了name之外，我们还想显示其他信息，比如info
                                info: d.info, // 自定义的额外信息
                            };
                        }),
                        blendMode: 'lighter',
                    },
                    {
                        type: 'lines3D',
                        coordinateSystem: 'globe',
                        data: linesList,
                        // lineStyle: {
                        //     normal: {
                        //         // type: 'dotted', // 把实现变成虚线
                        //         // color: '#586fb5', //连接线颜色
                        //         width: 1.5,//线条宽度
                        //         opacity: 0.8,
                        //         curveness: 0.3,//线条弧度 0-1
                        //     }
                        // },
                        blendMode: "lighter",
                        effect: {
                            show: true,
                        },
                        silent: false,
                    }
                ],

            });
        },
        // 世界地图
        wordEcharts() {
            echarts.init(document.getElementById('wordMap')).dispose();
            var myChart = echarts.init(document.getElementById('wordMap'));
            let that = this
            var nameMap = {
                "Canada": "加拿大",
                "Turkmenistan": "土库曼斯坦",
                "Saint Helena": "圣赫勒拿",
                "Lao PDR": "老挝",
                "Lithuania": "立陶宛",
                "Cambodia": "柬埔寨",
                "Ethiopia": "埃塞俄比亚",
                "Faeroe Is.": "法罗群岛",
                "Swaziland": "斯威士兰",
                "Palestine": "巴勒斯坦",
                "Belize": "伯利兹",
                "Argentina": "阿根廷",
                "Bolivia": "玻利维亚",
                "Cameroon": "喀麦隆",
                "Burkina Faso": "布基纳法索",
                "Aland": "奥兰群岛",
                "Bahrain": "巴林",
                "Saudi Arabia": "沙特阿拉伯",
                "Fr. Polynesia": "法属波利尼西亚",
                "Cape Verde": "佛得角",
                "W. Sahara": "西撒哈拉",
                "Slovenia": "斯洛文尼亚",
                "Guatemala": "危地马拉",
                "Guinea": "几内亚",
                "Dem. Rep. Congo": "刚果（金）",
                "Germany": "德国",
                "Spain": "西班牙",
                "Liberia": "利比里亚",
                "Netherlands": "荷兰",
                "Jamaica": "牙买加",
                "Solomon Is.": "所罗门群岛",
                "Oman": "阿曼",
                "Tanzania": "坦桑尼亚",
                "Costa Rica": "哥斯达黎加",
                "Isle of Man": "曼岛",
                "Gabon": "加蓬",
                "Niue": "纽埃",
                "Bahamas": "巴哈马",
                "New Zealand": "新西兰",
                "Yemen": "也门",
                "Jersey": "泽西岛",
                "Pakistan": "巴基斯坦",
                "Albania": "阿尔巴尼亚",
                "Samoa": "萨摩亚",
                "Czech Rep.": "捷克",
                "United Arab Emirates": "阿拉伯联合酋长国",
                "Guam": "关岛",
                "India": "印度",
                "Azerbaijan": "阿塞拜疆",
                "N. Mariana Is.": "北马里亚纳群岛",
                "Lesotho": "莱索托",
                "Kenya": "肯尼亚",
                "Belarus": "白俄罗斯",
                "Tajikistan": "塔吉克斯坦",
                "Turkey": "土耳其",
                "Afghanistan": "阿富汗",
                "Bangladesh": "孟加拉国",
                "Mauritania": "毛里塔尼亚",
                "Dem. Rep. Korea": "朝鲜",
                "Saint Lucia": "圣卢西亚",
                "Br. Indian Ocean Ter.": "英属印度洋领地",
                "Mongolia": "蒙古国",
                "France": "法国",
                "Cura?ao": "库拉索岛",
                "S. Sudan": "南苏丹",
                "Rwanda": "卢旺达",
                "Slovakia": "斯洛伐克",
                "Somalia": "索马里",
                "Peru": "秘鲁",
                "Vanuatu": "瓦努阿图",
                "Norway": "挪威",
                "Malawi": "马拉维",
                "Benin": "贝宁",
                "St. Vin. and Gren.": "圣文森特和格林纳丁斯",
                "Korea": "韩国",
                "Singapore": "新加坡",
                "Montenegro": "黑山",
                "Cayman Is.": "开曼群岛",
                "Togo": "多哥",
                "China": "中国",
                "Heard I. and McDonald Is.": "赫德岛和麦克唐纳群岛",
                "Armenia": "亚美尼亚",
                "Falkland Is.": "马尔维纳斯群岛（福克兰）",
                "Ukraine": "乌克兰",
                "Ghana": "加纳",
                "Tonga": "汤加",
                "Finland": "芬兰",
                "Libya": "利比亚",
                "Dominican Rep.": "多米尼加",
                "Indonesia": "印度尼西亚",
                "Mauritius": "毛里求斯",
                "Eq. Guinea": "赤道几内亚",
                "Sweden": "瑞典",
                "Vietnam": "越南",
                "Mali": "马里",
                "Russia": "俄罗斯",
                "Bulgaria": "保加利亚",
                "United States": "美国",
                "Romania": "罗马尼亚",
                "Angola": "安哥拉",
                "Chad": "乍得",
                "South Africa": "南非",
                "Fiji": "斐济",
                "Liechtenstein": "列支敦士登",
                "Malaysia": "马来西亚",
                "Austria": "奥地利",
                "Mozambique": "莫桑比克",
                "Uganda": "乌干达",
                "Japan": "日本",
                "Niger": "尼日尔",
                "Brazil": "巴西",
                "Kuwait": "科威特",
                "Panama": "巴拿马",
                "Guyana": "圭亚那合作共和国",
                "Madagascar": "马达加斯加",
                "Luxembourg": "卢森堡",
                "American Samoa": "美属萨摩亚",
                "Andorra": "安道尔",
                "Ireland": "爱尔兰",
                "Italy": "意大利",
                "Nigeria": "尼日利亚",
                "Turks and Caicos Is.": "特克斯和凯科斯群岛",
                "Ecuador": "厄瓜多尔",
                "U.S. Virgin Is.": "美属维尔京群岛",
                "Brunei": "文莱",
                "Australia": "澳大利亚",
                "Iran": "伊朗",
                "Algeria": "阿尔及利亚",
                "El Salvador": "萨尔瓦多",
                "CÃ´te d'Ivoire": "科特迪瓦",
                "Chile": "智利",
                "Puerto Rico": "波多黎各",
                "Belgium": "比利时",
                "Thailand": "泰国",
                "Haiti": "海地",
                "Iraq": "伊拉克",
                "S?o Tomé and Principe": "圣多美和普林西比",
                "Sierra Leone": "塞拉利昂",
                "Georgia": "格鲁吉亚",
                "Denmark": "丹麦",
                "Philippines": "菲律宾",
                "S. Geo. and S. Sandw. Is.": "南乔治亚岛和南桑威奇群岛",
                "Moldova": "摩尔多瓦",
                "Morocco": "摩洛哥",
                "Namibia": "纳米比亚",
                "Malta": "马耳他",
                "Guinea-Bissau": "几内亚比绍",
                "Kiribati": "基里巴斯",
                "Switzerland": "瑞士",
                "Grenada": "格林纳达",
                "Seychelles": "塞舌尔",
                "Portugal": "葡萄牙",
                "Estonia": "爱沙尼亚",
                "Uruguay": "乌拉圭",
                "Antigua and Barb.": "安提瓜和巴布达",
                "Lebanon": "黎巴嫩",
                "Uzbekistan": "乌兹别克斯坦",
                "Tunisia": "突尼斯",
                "Djibouti": "吉布提",
                "Greenland": "丹麦",
                "Timor-Leste": "东帝汶",
                "Dominica": "多米尼克",
                "Colombia": "哥伦比亚",
                "Burundi": "布隆迪",
                "Bosnia and Herz.": "波斯尼亚和黑塞哥维那",
                "Cyprus": "塞浦路斯",
                "Barbados": "巴巴多斯",
                "Qatar": "卡塔尔",
                "Palau": "帕劳",
                "Bhutan": "不丹",
                "Sudan": "苏丹",
                "Nepal": "尼泊尔",
                "Micronesia": "密克罗尼西亚",
                "Bermuda": "百慕大",
                "Suriname": "苏里南",
                "Venezuela": "委内瑞拉",
                "Israel": "以色列",
                "St. Pierre and Miquelon": "圣皮埃尔和密克隆群岛",
                "Central African Rep.": "中非共和国",
                "Iceland": "冰岛",
                "Zambia": "赞比亚",
                "Senegal": "塞内加尔",
                "Papua New Guinea": "巴布亚新几内亚",
                "Trinidad and Tobago": "特立尼达和多巴哥",
                "Zimbabwe": "津巴布韦",
                "Jordan": "约旦",
                "Gambia": "冈比亚",
                "Kazakhstan": "哈萨克斯坦",
                "Poland": "波兰",
                "Eritrea": "厄立特里亚",
                "Kyrgyzstan": "吉尔吉斯斯坦",
                "Montserrat": "蒙特塞拉特",
                "New Caledonia": "新喀里多尼亚",
                "Macedonia": "马其顿",
                "Paraguay": "巴拉圭",
                "Latvia": "拉脱维亚",
                "Hungary": "匈牙利",
                "Syria": "叙利亚",
                "Honduras": "洪都拉斯",
                "Myanmar": "缅甸",
                "Mexico": "墨西哥",
                "Egypt": "埃及",
                "Nicaragua": "尼加拉瓜",
                "Cuba": "古巴",
                "Serbia": "塞尔维亚",
                "Comoros": "科摩罗",
                "United Kingdom": "英国",
                "Fr. S. Antarctic Lands": "南极洲",
                "Congo": "刚果（布）",
                "Greece": "希腊",
                "Sri Lanka": "斯里兰卡",
                "Croatia": "克罗地亚",
                "Botswana": "博茨瓦纳",
                "Siachen Glacier": "锡亚琴冰川地区"
            };
            var house = [
                // {
                //     houseAdd: "中国",
                //     color: 'red'
                // },
                // {
                //     houseAdd: "Fr. Polynesia",
                //     color: 'blue'
                // }
            ]
            var regions = []; // 定义需要高亮的地区（仓库位置国家）

            dangerCountry({ orgId: this.deptId }).then(res => {
                console.log('风险国家', res);
                if (res.success && res.code == 200) {
                    var color = ''
                    res.data.data.forEach(item => {
                        if (item.dangerRank == 3) {
                            color = '#fd0001'
                        } else if (item.dangerRank == 2) {
                            color = '#f75e02'
                        } else if (item.dangerRank == 1) {
                            color = '#feca02'
                        }
                        house.push({
                            houseAdd: item.country,
                            color: color
                        })
                    })

                    house.forEach((item, i) => {
                        regions.push(
                            {
                                name: item.houseAdd,
                                itemStyle: {
                                    normal: {
                                        areaColor: item.color
                                    },
                                },

                            },
                        );
                    })
                    var option = {
                        geo: {
                            map: 'world',
                            label: {
                                emphasis: {
                                    show: true,
                                    color: '#fff'
                                }
                            },
                            roam: true,
                            itemStyle: {
                                normal: {
                                    areaColor: 'rgba(0, 26, 45, 0)',
                                    borderColor: '#9ac5f0',
                                    borderWidth: 1.5,
                                    shadowColor: '#3697AD',
                                    shadowBlur: 30
                                },
                                emphasis: {
                                    areaColor: 'rgba(0, 26, 45, 0)'
                                }
                            },
                            zoom: 1.2,
                            regions: regions
                        },
                        series: [
                            {
                                type: 'effectScatter',
                                coordinateSystem: 'geo',
                                zlevel: 2,
                                symbolSize: [window.innerWidth / 100 * 1.2, window.innerWidth / 100 * 0.6],
                                rippleEffect: {
                                    brushType: 'stroke',
                                    scale: 5,
                                    period: 8
                                },
                                itemStyle: {
                                    normal: {
                                        color: '#44FCF7'
                                    }
                                },
                                data: that.earthData.map(function (d) {
                                    return {
                                        name: d.name,
                                        value: [d.value[0], d.value[1], 0],
                                        label: {
                                            show: false, // 默认不显示标签
                                            emphasis: { // 高亮状态的样式定义
                                                formatter: function (params) {
                                                    // 根据需要展示的内容定制
                                                    let content = '项目名称\n\n' + params.data.name; // 可以根据data里的属性定制内容
                                                    if (params.data.info) { // 假设数据中还有其他属性info
                                                        content += '\n\n中方人数:    ' + params.data.info.chinesePersonnelNum + '\n\n外籍人数:    ' + params.data.info.foreignNationalsNum;
                                                    }
                                                    return content;
                                                },
                                                position: 'top',
                                                align: 'left',
                                                show: true, // 鼠标悬浮时显示
                                                color: "#fff",
                                                backgroundColor: 'rgba(8, 66, 165, 0.8)',
                                                padding: 10, // 上右下左的内边距分别为 10px
                                                borderWidth: 1, // 边框宽度为 1 像素
                                                borderColor: '#34a0e9', // 红色边框
                                                borderRadius: 5,
                                            }
                                        },
                                        // 假设除了name之外，我们还想显示其他信息，比如info
                                        info: d.info, // 自定义的额外信息
                                    };
                                }),
                            },
                        ]
                    };
                    myChart.resize();
                    window.addEventListener("resize", function () {
                        myChart.resize();
                    });
                    myChart.setOption(option)
                }
            })

        },
        // 境外项目中方外籍人员占比数
        getEchartsL0() {
            echarts.init(document.getElementById('echartsL0')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB01_Y`
            visualization(params).then(res => {
                let data = res.data.data.ZB01_Y
                let that = this
                var salvProName = [];
                var salvProValue = [];
                var proportion = []
                var salvProMax = [];//背景按最大值

                var myChart = echarts.init(document.getElementById('echartsL0'));
                var option = null;
                if (data.length > 0) {
                    data.forEach(item => {
                        salvProName.push(item.orgName)
                        salvProValue.push(item.value4.toFixed(2) + '%')
                        proportion.push(item.value5.toFixed(2) + '%')
                    })
                    option = {
                        grid: {
                            left: '2%',
                            right: '10%',
                            bottom: '-7%',
                            top: '15%',
                            containLabel: true
                        },
                        title: {
                            text: '项目',
                            textStyle: {
                                color: '#00aaff',
                                fontSize: this.fontSize * 0.326
                            }
                        },
                        legend: {
                            name: ['境内', '境外'],
                            textStyle: {
                                color: '#e3feff',
                                fontSize: this.fontSize * 0.326
                            },
                            itemWidth: this.fontSize * 0.326,
                            itemHeight: this.fontSize * 0.326,
                            x: 'right'
                        },
                        xAxis: {
                            show: false,
                            type: 'value'
                        },
                        yAxis: [
                            {
                                type: 'category',
                                inverse: true,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                data: []
                            },
                            // {
                            //     type: 'category',
                            //     inverse: true,
                            //     axisTick: 'none',
                            //     axisLine: 'none',
                            //     show: true,
                            //     axisLabel: {
                            //         textStyle: {
                            //             color: '#e3feff',
                            //             fontSize: this.fontSize * 0.326
                            //         }
                            //     },
                            //     data: [],
                            // }
                        ],
                        series: [
                            {
                                name: '境内',
                                type: 'bar',
                                zlevel: 1,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: '#f5824b'
                                        }, {
                                            offset: 1,
                                            color: '#ddba31'
                                        }]),
                                    },
                                },
                                barWidth: this.fontSize * 0.233,
                                label: {
                                    show: true,
                                    position: 'right',
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    },
                                    formatter: '{c}%'
                                },
                                data: [],
                            },
                            {
                                name: '境外',
                                type: 'bar',
                                zlevel: 1,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: 'rgb(46,200,207,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgb(57,89,255,1)'
                                        }]),
                                    },
                                },
                                label: {
                                    show: true,
                                    position: 'right',
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    },
                                    formatter: '{c}%'
                                },
                                barWidth: this.fontSize * 0.233,
                                data: [],
                            },
                            // {
                            //     name: '背景',
                            //     type: 'bar',
                            //     barWidth: this.fontSize * 0.233,
                            //     barGap: '-100%',
                            //     data: [],
                            //     itemStyle: {
                            //         normal: {
                            //             color: 'rgba(0, 55, 128,0.5)',
                            //             barBorderRadius: 5,
                            //         }
                            //     },
                            // },
                        ]
                    };
                    var Interval = null;
                    clearInterval(Interval)
                    InItOpen();
                    Interval = setInterval(InItOpen, 3000);
                    function InItOpen() {
                        if (salvProName.length > 5) {
                            option.yAxis[0].data = that.baseData(option, salvProName, 5);
                            option.series[0].data = that.baseData(option, salvProValue, 5);
                            option.series[1].data = that.baseData(option, salvProMax, 5);
                        } else {
                            option.yAxis[0].data = salvProName;
                            option.series[0].data = salvProValue;
                            option.series[1].data = salvProMax;
                        }
                        myChart.setOption(option)
                    }
                } else {
                    option = this.echartsNoData()
                    myChart.setOption(option)
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        // 境外风险项目数占比统计
        getEchartsL1() {
            echarts.init(document.getElementById('echartsL1')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00101_Y`
            visualization(params).then(res => {
                // console.log('境外风险项目数占比统计', res.data.data);
                let data = res.data.data.ZB00101_Y
                let that = this
                var salvProName = [];
                var salvProValue = [];

                var myChart = echarts.init(document.getElementById('echartsL1'));
                var option = null;
                if (data.length > 0) {
                    data.forEach(item => {
                        salvProName.push(item.orgName)
                        salvProValue.push(item.value.toFixed(2))
                    })
                    option = {
                        grid: {
                            left: '2%',
                            right: '10%',
                            bottom: '-7%',
                            top: '15%',
                            containLabel: true
                        },
                        title: {
                            text: '项目',
                            textStyle: {
                                color: '#00aaff',
                                fontSize: this.fontSize * 0.326
                            }
                        },
                        legend: {
                            data: ['风险项目数占比率'],
                            textStyle: {
                                color: '#00aaff',
                                fontSize: this.fontSize * 0.326
                            },
                            itemWidth: this.fontSize * 0.326,
                            itemHeight: this.fontSize * 0.326,
                            x: 'right'
                        },
                        xAxis: {
                            show: false,
                            type: 'value'
                        },
                        yAxis: [
                            {
                                type: 'category',
                                inverse: true,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                data: []
                            },
                        ],
                        series: [
                            {
                                name: '风险项目数占比率',
                                type: 'bar',
                                zlevel: 1,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: 'rgb(46,200,207,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgb(57,89,255,1)'
                                        }]),
                                    },
                                },
                                label: {
                                    show: true,
                                    position: 'right',
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    },
                                    formatter: '{c}%'
                                },
                                barWidth: this.fontSize * 0.233,
                                data: [],
                            },
                        ]
                    };
                    var Interval = null;
                    clearInterval(Interval)
                    InItOpen();
                    Interval = setInterval(InItOpen, 3000);
                    function InItOpen() {
                        if (salvProName.length > 5) {
                            option.yAxis[0].data = that.baseData(option, salvProName, 5);
                            option.series[0].data = that.baseData(option, salvProValue, 5);
                        } else {
                            option.yAxis[0].data = salvProName;
                            option.series[0].data = salvProValue;
                        }
                        myChart.setOption(option)
                    }
                } else {
                    option = this.echartsNoData()
                    myChart.setOption(option)
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        // 境外风险项目人数占比统计
        getEchartsL2() {
            echarts.init(document.getElementById('echartsL2')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00102_Y`
            visualization(params).then(res => {
                // console.log('境外风险项目人数占比统计', res.data.data);
                let data = res.data.data.ZB00102_Y
                let that = this
                var salvProName = [];
                var salvProValue = [];

                var myChart = echarts.init(document.getElementById('echartsL2'));
                var option = null;
                if (data.length > 0) {
                    data.forEach(item => {
                        salvProName.push(item.orgName)
                        salvProValue.push(item.value.toFixed(2))
                    })
                    option = {
                        grid: {
                            left: '2%',
                            right: '10%',
                            bottom: '-7%',
                            top: '15%',
                            containLabel: true
                        },
                        title: {
                            text: '项目',
                            textStyle: {
                                color: '#00aaff',
                                fontSize: this.fontSize * 0.326
                            }
                        },
                        legend: {
                            data: ['境外项目人员数占比'],
                            textStyle: {
                                color: '#00aaff',
                                fontSize: this.fontSize * 0.326
                            },
                            itemWidth: this.fontSize * 0.326,
                            itemHeight: this.fontSize * 0.326,
                            x: 'right'
                        },
                        xAxis: {
                            show: false,
                            type: 'value'
                        },
                        yAxis: [
                            {
                                type: 'category',
                                inverse: true,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                data: []
                            },
                        ],
                        series: [
                            {
                                name: '境外项目人员数占比',
                                type: 'bar',
                                zlevel: 1,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: 'rgb(46,200,207,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgb(57,89,255,1)'
                                        }]),
                                    },
                                },
                                label: {
                                    show: true,
                                    position: 'right',
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    },
                                    formatter: '{c}%'
                                },
                                barWidth: this.fontSize * 0.233,
                                data: [],
                            },
                        ]
                    };
                    var Interval = null;
                    clearInterval(Interval)
                    InItOpen();
                    Interval = setInterval(InItOpen, 3000);
                    function InItOpen() {
                        if (salvProName.length > 5) {
                            option.yAxis[0].data = that.baseData(option, salvProName, 5);
                            option.series[0].data = that.baseData(option, salvProValue, 5);
                        } else {
                            option.yAxis[0].data = salvProName;
                            option.series[0].data = salvProValue;
                        }
                        myChart.setOption(option)
                    }
                } else {
                    option = this.echartsNoData()
                    myChart.setOption(option)
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        // “零违章”评选情况个
        getViolationOfRegulations() {
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0063_Y&indexCodes=ZB0064_Y`
            visualization(params).then(res => {
                // console.log('“零违章”评选情况个', res.data.data);
                let data = res.data.data
                this.ViolationOfRegulations.personnelAmount = data.ZB0063_Y.length == 0 ? 0 : data.ZB0063_Y[0].value
                this.ViolationOfRegulations.classAmount = data.ZB0064_Y.length == 0 ? 0 : data.ZB0064_Y[0].value
            })
        },
        // 年度应急演练总次数
        getDrillNum() {
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0097_Y&indexCodes=ZB0062_Y`
            visualization(params).then(res => {
                // console.log('年度应急演练总次数', res.data.data);
                let data = res.data.data
                this.drillNum.abroad = data.ZB0097_Y.length == 0 ? 0 : data.ZB0097_Y[0].value
                this.drillNum.domestic = data.ZB0062_Y.length == 0 ? 0 : data.ZB0062_Y[0].value
            })
        },
        // 安全培训覆盖率
        getEchartsRT1() {
            echarts.init(document.getElementById('echartsRT1')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0067_Y&indexCodes=ZB0069_Y&indexCodes=ZB0099_Y`
            visualization(params).then(res => {
                let data = res.data.data
                // console.log('安全培训覆盖率', data);
                this.safetyRate.trainingAmount = data.ZB0067_Y.length == 0 ? 0 : data.ZB0067_Y[0].value // 安全培训次数
                this.safetyRate.trainingPersonnel = data.ZB0069_Y.length == 0 ? 0 : data.ZB0069_Y[0].value // 安全培训人次
                this.safetyRate.rate = data.ZB0099_Y.length == 0 ? 0 : data.ZB0099_Y[0].value
                var myChart = echarts.init(document.getElementById('echartsRT1'));
                var option = {
                    title: [
                        {
                            text: this.safetyRate.rate + '%',
                            x: 'center',
                            top: 'center',
                            left: 'center',
                            textStyle: {
                                fontSize: this.fontSize * 0.93,
                                color: '#FFFFFF',
                                fontFamily: 'DINAlternate-Bold, DINAlternate',
                                foontWeight: '600'
                            }
                        }
                    ],
                    polar: {
                        radius: ['65%', '80%'],
                        center: ['50%', '50%']
                    },
                    angleAxis: {
                        max: 100,
                        show: false
                    },
                    radiusAxis: {
                        type: 'category',
                        show: true,
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            roundCap: true,
                            barWidth: 90,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(66, 66, 66, .3)'
                            },
                            data: [this.safetyRate.rate],
                            coordinateSystem: 'polar',
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: '#1b9df0'
                                        },
                                        {
                                            offset: 0.3,
                                            color: '#16CEB9'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ])
                                }
                            }
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        // 培训次数统计
        getEchartsRT2() {
            echarts.init(document.getElementById('echartsRT2')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0083_M`
            visualization(params).then(res => {
                let data = res.data.data.ZB0083_M
                let monthData = []
                let monthValue = []
                data.forEach(item => {
                    monthData.push(item.month + '月')
                    monthValue.push(item.value)
                })
                var myChart = echarts.init(document.getElementById('echartsRT2'));
                var option = null;
                if (data.length > 0) {
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        legend: {
                            data: ['实际'],
                            right: '4%',
                            top: '10%',
                            textStyle: {
                                color: '#e3feff',
                                fontSize: this.fontSize * 0.279
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '5%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: monthData,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '单位：次数',
                                splitLine: {
                                    lineStyle: {
                                        type: "dashed",
                                        color: "#396389"
                                    }
                                },
                                nameTextStyle: {
                                    color: '#e3feff',
                                    fontSize: this.fontSize * 0.326
                                },
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                            }
                        ],
                        series: [
                            {
                                name: '实际',
                                type: 'line',
                                stack: 'Total',
                                smooth: true,
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [
                                                {
                                                    offset: 0,
                                                    color: 'rgba(27, 154, 237,0.5)'
                                                },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(27, 154, 237,0.1)'
                                                }
                                            ],
                                            false
                                        ),
                                        shadowBlur: 10
                                    }
                                },
                                emphasis: {
                                    focus: 'series'
                                },
                                data: monthValue
                            }
                        ]
                    };
                } else {
                    option = this.echartsNoData()
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        // 本月公司培训次数
        getEchartsRT3() {
            let data = []
            let that = this
            var salvProName = [];
            var salvProValue = [];
            var proportion = []
            var salvProMax = [];//背景按最大值
            echarts.init(document.getElementById('echartsRT3')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0083`
            visualization(params).then(res => {
                // console.log('培训次数统计', res.data.data);
                data.forEach(item => {
                    salvProName.push(item.label)
                    salvProValue.push(item.value.toFixed(2))
                    proportion.push(item.value.toFixed(2) + '%')
                    salvProMax.push(100)
                })
                var myChart = echarts.init(document.getElementById('echartsRT3'));
                var option = null;
                if (data.length > 0) {
                    option = {

                        grid: {
                            left: '2%',
                            right: '2%',
                            bottom: '-7%',
                            top: '15%',
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'none'
                            },
                            formatter: function (params) {
                                return params[0].name + ' : ' + params[0].value + '%'
                            }
                        },
                        xAxis: {
                            show: false,
                            type: 'value'
                        },
                        yAxis: [
                            {
                                type: 'category',
                                inverse: true,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                data: []
                            },
                            {
                                type: 'category',
                                inverse: true,
                                axisTick: 'none',
                                axisLine: 'none',
                                show: true,
                                axisLabel: {
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                                data: [],
                            }
                        ],
                        series: [
                            {
                                name: '值',
                                type: 'bar',
                                zlevel: 1,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: 'rgb(46,200,207,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgb(57,89,255,1)'
                                        }]),
                                    },
                                },
                                barWidth: this.fontSize * 0.233,
                                data: [],

                            },
                            {
                                name: '背景',
                                type: 'bar',
                                barWidth: this.fontSize * 0.233,
                                barGap: '-100%',
                                data: [],
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(0, 55, 128,0.5)',
                                        barBorderRadius: 5,
                                    }
                                },
                            },
                        ]
                    };
                    var Interval = null;
                    clearInterval(Interval)
                    InItOpen();
                    Interval = setInterval(InItOpen, 3000);
                    function InItOpen() {
                        if (salvProName.length > 5) {
                            option.yAxis[0].data = that.baseData(option, salvProName, 5);
                            option.yAxis[1].data = that.baseData(option, proportion, 5);
                            option.series[0].data = that.baseData(option, salvProValue, 5);
                            option.series[1].data = that.baseData(option, salvProMax, 5);
                        } else {
                            option.yAxis[0].data = salvProName;
                            option.yAxis[1].data = proportion;
                            option.series[0].data = salvProValue;
                            option.series[1].data = salvProMax;
                        }
                        myChart.setOption(option)
                    }
                } else {
                    option = this.echartsNoData()
                    myChart.setOption(option)
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        // 年度安全检查统计
        getEchartsRT4() {
            echarts.init(document.getElementById('echartsRT4')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00108_Y&indexCodes=ZB0042_Y&indexCodes=ZB0041_Y`
            visualization(params).then(res => {
                // let data = res.data.data
                // console.log('年度安全检查统计', res.data.data);

                let data = res.data.data
                let allAmount = data.ZB00108_Y.length == 0 ? 0 : data.ZB00108_Y[0].value
                let pieSource = {
                    专项检测: data.ZB0042_Y.length == 0 ? 0 : data.ZB0042_Y[0].value,
                    项目自查次数: 0,
                    政府检查次数: data.ZB0041_Y.length == 0 ? 0 : data.ZB0041_Y[0].value,
                    领导带班检查次数: 0
                }
                var legendColor = ['#5EC4FF', '#ECE427', '#12E7A3', '#00d1ff']
                var richStyle = {};
                var source = this.initData(pieSource, legendColor, richStyle);
                var myChart = echarts.init(document.getElementById('echartsRT4'));
                var option = {
                    title: {
                        text: '总数量',
                        subtext: allAmount + '',
                        textStyle: {
                            fontSize: this.fontSize * 0.326,
                            color: '#9dc0d9',
                            lineHeight: this.fontSize * 0.465,
                            fontWeight: 200
                        },
                        subtextStyle: {
                            fontSize: this.fontSize * 0.93,
                            color: '#9fe5ff',
                            fontWeight: 800
                        },
                        textAlign: 'center',
                        left: '19%',
                        top: '35%'
                    },
                    tooltip: {
                        trigger: 'item',
                        textStyle: {
                            color: '#003eff',
                            fontSize: this.fontSize * 0.326
                        },
                        formatter: '{a} <br/>{b} : {c} 次'
                    },
                    color: legendColor,
                    legend: [
                        {
                            orient: 'vertical',
                            left: '40%',
                            y: 'center',
                            icon: 'circle',
                            textStyle: {
                                color: '#bfe4fe',
                                fontSize: this.fontSize * 0.302,
                                rich: richStyle,
                                width: this.fontSize * 4.651,
                                height: this.fontSize * 0.302,
                            },
                            padding: [this.fontSize * 0.698, 0, this.fontSize * 0.698, 0],
                            itemWidth: this.fontSize * 0.233,
                            itemHeight: this.fontSize * 0.186,
                            itemGap: this.fontSize * 0.465,
                            data: source.legendData.slice(0, 4),
                            formatter: function (name) {
                                let oa = option.series[0].data;
                                let total = 0;
                                oa.forEach((item, index) => {
                                    total += item.value;
                                });
                                for (let i = 0; i < oa.length; i++) {
                                    if (name == oa[i].name) {
                                        // return name + '  ' + '{style'+i+'|' + (oa[i].value / total * 100).toFixed(2) + '%}';
                                        return (
                                            name + '{style' + i + '|' + oa[i].value + '次}'
                                        );
                                    }
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '年度安全检查统计',
                            type: 'pie',
                            radius: ['50%', '60%'],
                            center: ['20%', '50%'],
                            data: source.source,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: false
                                    },
                                    shadowColor: '#438bff',
                                    // shadowBlur: 10,
                                    borderWidth: 3,
                                    borderColor: 'rgba(0,0,0,0.3)'
                                }
                            }
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        initData(source, legendColor, richStyle) {
            legendColor.map((item, index) => {
                richStyle['style' + index] = {};
                richStyle['style' + index]['color'] = item;
                richStyle['style' + index]['align'] = 'right';
                richStyle['style' + index]['fontSize'] = this.fontSize * 0.326;
            });
            if (Object.prototype.toString.call(source) === '[object Object]') {
                const arr = [];
                const legendData = [];
                Object.entries(source).forEach((value) => {
                    legendData.push(value[0]);
                    let obj = {};
                    obj.name = value[0];
                    obj.value = value[1];
                    arr.push(obj);
                });
                source = arr;
                return {
                    source,
                    legendData
                };
            } else if (
                source &&
                Object.prototype.toString.call(source) === '[object Array]'
            ) {
                let legendData = [];
                source.forEach((item, index) => {
                    legendData.push(item.name);
                });
                return {
                    source,
                    legendData
                };
            }
        },
        // 应急预案建设率
        getEchartsRB1() {
            echarts.init(document.getElementById('echartsRB1')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00110_Y`
            visualization(params).then(res => {
                // console.log('应急预案建设率', res.data.data);
                let data = res.data.data.ZB00110_Y
                let value = data.length == 0 ? 0 : data[0].value
                var myChart = echarts.init(document.getElementById('echartsRB1'));
                var option = {
                    graphic: {
                        elements: [
                            {
                                type: 'image',
                                // z: 3,
                                style: {
                                    image: require('../../../assets/visualization/IntelligentSecurity/programme.png'),
                                    width: this.fontSize * 1,
                                    height: this.fontSize * 1,
                                },
                                left: this.fontSize * 2.3,
                                top: 'center',
                            },
                        ],
                    },
                    title: [
                        {
                            text: '建设率：\n',
                            subtext: value + '%',
                            textStyle: {
                                fontSize: this.fontSize * 0.326,
                                color: '#9dc0d9',
                            },
                            subtextStyle: {
                                fontSize: this.fontSize * 0.93,
                                color: '#00AAFF',
                            },
                            x: '50%',
                            top: '40%'
                        }
                    ],
                    series: [
                        {
                            name: '最外层圆圈',
                            type: 'pie',
                            clockWise: true,
                            radius: ['70%', '80%'],
                            center: [this.fontSize * 2.791, '50%'],
                            labelLine: {
                                show: false
                            },
                            label: {
                                show: false
                            },
                            itemStyle: {
                                borderWidth: 4,
                                color: 'rgb(25, 37, 64, 0.5)'
                            },
                            hoverAnimation: false,
                            data: [20, 20, 20, 20, 20]
                        },
                        {
                            name: '下层圆圈',
                            type: 'pie',
                            clockWise: true,
                            z: 2,
                            radius: ['50%', '65%'],
                            center: [this.fontSize * 2.791, '50%'],
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: 'rgb(17, 18, 40)',
                                color: 'rgb(32, 64, 116)'
                            },
                            hoverAnimation: false,
                            data: [10, 10, 10, 10, 10, 10, 10, 10]
                        },
                        {
                            name: '上层圆圈',
                            type: 'pie',
                            z: 3,
                            clockWise: true,
                            radius: ['50%', '65%'],
                            center: [this.fontSize * 2.791, '50%'],
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                            hoverAnimation: false,
                            data: [
                                {
                                    value: value,
                                    name: '',
                                    itemStyle: {
                                        color: {
                                            // 完成的圆环的颜色
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: 'rgb(0, 243, 245)' // 0% 处的颜色
                                                },
                                                {
                                                    offset: 1,
                                                    color: 'rgb(0, 158, 254)' // 100% 处的颜色
                                                }
                                            ]
                                        },
                                        label: {
                                            show: false
                                        },
                                        labelLine: {
                                            show: false
                                        }
                                    }
                                },
                                {
                                    value: 100 - value,
                                    itemStyle: {
                                        normal: {
                                            color: 'transparent'
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        // 千人重伤率
        getEchartsRB2() {
            echarts.init(document.getElementById('echartsRB2')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00109_Y`
            visualization(params).then(res => {
                let data = res.data.data.ZB00109_Y
                let value = data.length == 0 ? 0 : data[0].value
                var myChart = echarts.init(document.getElementById('echartsRB2'));
                var option = {
                    graphic: {
                        elements: [
                            {
                                type: 'image',
                                // z: 3,
                                style: {
                                    image: require('../../../assets/visualization/IntelligentSecurity/health.png'),
                                    width: this.fontSize * 1,
                                    height: this.fontSize * 1,
                                },
                                left: this.fontSize * 2.3,
                                top: 'center',
                            },
                        ],
                    },
                    title: [
                        {
                            text: '千人重伤率：\n',
                            subtext: value + '%',
                            textStyle: {
                                fontSize: this.fontSize * 0.326,
                                color: '#9dc0d9',
                            },
                            subtextStyle: {
                                fontSize: this.fontSize * 0.93,
                                color: '#00AAFF',
                            },
                            x: '50%',
                            top: '40%'
                        }
                    ],
                    series: [
                        {
                            name: '最外层圆圈',
                            type: 'pie',
                            clockWise: true,
                            radius: ['70%', '80%'],
                            center: [this.fontSize * 2.791, '50%'],
                            labelLine: {
                                show: false
                            },
                            label: {
                                show: false
                            },
                            itemStyle: {
                                borderWidth: 4,
                                color: 'rgb(25, 37, 64, 0.5)'
                            },
                            hoverAnimation: false,
                            data: [20, 20, 20, 20, 20]
                        },
                        {
                            name: '下层圆圈',
                            type: 'pie',
                            clockWise: true,
                            z: 2,
                            radius: ['50%', '65%'],
                            center: [this.fontSize * 2.791, '50%'],
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: 'rgb(17, 18, 40)',
                                color: 'rgb(32, 64, 116)'
                            },
                            hoverAnimation: false,
                            data: [10, 10, 10, 10, 10, 10, 10, 10]
                        },
                        {
                            name: '上层圆圈',
                            type: 'pie',
                            z: 3,
                            clockWise: true,
                            radius: ['50%', '65%'],
                            center: [this.fontSize * 2.791, '50%'],
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                            hoverAnimation: false,
                            data: [
                                {
                                    value: value,
                                    name: '',
                                    itemStyle: {
                                        color: {
                                            // 完成的圆环的颜色
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: 'rgb(0, 243, 245)' // 0% 处的颜色
                                                },
                                                {
                                                    offset: 1,
                                                    color: 'rgb(0, 158, 254)' // 100% 处的颜色
                                                }
                                            ]
                                        },
                                        label: {
                                            show: false
                                        },
                                        labelLine: {
                                            show: false
                                        }
                                    }
                                },
                                {
                                    value: 100 - value,
                                    itemStyle: {
                                        normal: {
                                            color: 'transparent'
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        // 总体隐患整改率
        getEchartsRB3() {
            echarts.init(document.getElementById('echartsRB3')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00100_Y&indexCodes=ZB0049_Y&indexCodes=ZB00103_Y`
            visualization(params).then(res => {
                let data = res.data.data
                // console.log('总体隐患整改率', data);
                this.dangerRate.dangerAmount = data.ZB00100_Y.length == 0 ? 0 : data.ZB00100_Y[0].value // 隐患总数
                this.dangerRate.rectified = data.ZB0049_Y.length == 0 ? 0 : data.ZB0049_Y[0].value // 已整改隐患
                this.dangerRate.rate = data.ZB00103_Y.length == 0 ? 0 : data.ZB00103_Y[0].value // 百分比
                var myChart = echarts.init(document.getElementById('echartsRB3'));
                var option = {
                    title: [
                        {
                            text: this.dangerRate.rate + '%',
                            x: 'center',
                            top: 'center',
                            left: 'center',
                            textStyle: {
                                fontSize: this.fontSize * 0.93,
                                color: '#FFFFFF',
                                fontFamily: 'DINAlternate-Bold, DINAlternate',
                                foontWeight: '600'
                            }
                        }
                    ],
                    polar: {
                        radius: ['65%', '80%'],
                        center: ['50%', '50%']
                    },
                    angleAxis: {
                        max: 100,
                        show: false
                    },
                    radiusAxis: {
                        type: 'category',
                        show: true,
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            roundCap: true,
                            barWidth: 90,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(66, 66, 66, .3)'
                            },
                            data: [this.dangerRate.rate],
                            coordinateSystem: 'polar',
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: '#1b9df0'
                                        },
                                        {
                                            offset: 0.3,
                                            color: '#16CEB9'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ])
                                }
                            }
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        // 公司隐患整改率
        getEchartsRB4() {
            echarts.init(document.getElementById('echartsRB4')).dispose();
            let that = this
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCode=ZB00107_Y`
            visualization(params).then(res => {
                let data = res.data.data.records

                var salvProName = [];
                var salvProValue = [];
                var proportion = []
                var salvProMax = [];//背景按最大值
                data.forEach(item => {
                    salvProName.push(item.orgName)
                    salvProValue.push(item.value.toFixed(2))
                    proportion.push(item.value.toFixed(2) + '%')
                    salvProMax.push(100)
                })
                var myChart = echarts.init(document.getElementById('echartsRB4'));
                var option = null;
                if (data.length > 0) {
                    option = {
                        title: [
                            {
                                text: '公司',
                                textStyle: { color: '#00aaff', fontSize: this.fontSize * 0.326 },
                                left: 5, top: 5
                            },
                            {
                                text: '整改率',
                                textStyle: { color: '#00aaff', fontSize: this.fontSize * 0.326 },
                                right: 5, top: 5
                            }
                        ],
                        grid: {
                            left: '2%',
                            right: '2%',
                            bottom: '-7%',
                            top: '15%',
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'none'
                            },
                            formatter: function (params) {
                                return params[0].name + ' : ' + params[0].value + '%'
                            }
                        },
                        xAxis: {
                            show: false,
                            type: 'value'
                        },
                        yAxis: [
                            {
                                type: 'category',
                                inverse: true,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    },
                                    formatter: function (params) {
                                        var val = '';
                                        if (params.length > 4) {
                                            val = params.substr(0, 4) + '...';
                                            return val;
                                        } else {
                                            return params;
                                        }
                                    },
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                data: []
                            },
                            {
                                type: 'category',
                                inverse: true,
                                axisTick: 'none',
                                axisLine: 'none',
                                show: true,
                                axisLabel: {
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.326
                                    }
                                },
                                data: [],
                            }
                        ],
                        series: [
                            {
                                name: '值',
                                type: 'bar',
                                zlevel: 1,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: 'rgb(46,200,207,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgb(57,89,255,1)'
                                        }]),
                                    },
                                },
                                barWidth: this.fontSize * 0.233,
                                data: [],

                            },
                            {
                                name: '背景',
                                type: 'bar',
                                barWidth: this.fontSize * 0.233,
                                barGap: '-100%',
                                data: [],
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(0, 55, 128,0.5)',
                                        barBorderRadius: 5,
                                    }
                                },
                            },
                        ]
                    };
                    var Interval = null;
                    clearInterval(Interval)
                    InItOpen();
                    Interval = setInterval(InItOpen, 3000);
                    function InItOpen() {
                        if (salvProName.length > 5) {
                            option.yAxis[0].data = that.baseData(option, salvProName, 5);
                            option.yAxis[1].data = that.baseData(option, proportion, 5);
                            option.series[0].data = that.baseData(option, salvProValue, 5);
                            option.series[1].data = that.baseData(option, salvProMax, 5);
                        } else {
                            option.yAxis[0].data = salvProName;
                            option.yAxis[1].data = proportion;
                            option.series[0].data = salvProValue;
                            option.series[1].data = salvProMax;
                        }
                        myChart.setOption(option)
                    }
                } else {
                    option = this.echartsNoData()
                    myChart.setOption(option)
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        // 动态数据
        baseData(option, name, size) {
            var NameData = [];
            var Data_Name = name;
            Data_Name.push(Data_Name[0])
            Data_Name.splice(0, 1);
            for (var i = 0; i < Data_Name.length; i++) {
                NameData.push(Data_Name[i]);
                if ((i + 1) == size) {
                    break;
                }
            }
            return NameData;
        },
        // 是否开启滚动
        openTimer(name) {
            let that = this;
            switch (name) {
                case "tableBodyLeft":
                    if (that.projectTableLeft.length <= 5) {
                        return false;
                    } else {
                        that.xmdtToup("#tableBodyLeft", 3000, 1.047);
                    }
                    break;
                case "tableBodyRight":
                    if (that.projectTableRight.length <= 5) {
                        return false;
                    } else {
                        that.xmdtToup("#tableBodyRight", 3000, 1.047);
                    }
                    break;
            }
        },
        xmdtToup(dom, speed, goHeight) {
            const domElement = document.querySelector(dom);
            const everyJump = () => {
                domElement.style.transition = "margin-top 0.5s ease-in-out";
                domElement.style.marginTop = `${-1 * goHeight}vw`;
                domElement.addEventListener(
                    "transitionend",
                    () => {
                        domElement.style.transition = "";
                        domElement.style.marginTop = "0px";
                        if (dom == '#tableBodyLeft') {
                            const firstChild = this.projectTableLeft.shift();
                            this.projectTableLeft.push(firstChild);
                        } else {
                            const firstChild = this.projectTableRight.shift();
                            this.projectTableRight.push(firstChild);
                        }
                        setTimeout(everyJump, speed);
                    },
                    { once: true }
                );
            };
            everyJump();
        },
        // 无数据处理
        echartsNoData() {
            return {
                title: {
                    text: "{B|}",
                    left: 'center',
                    top: 'center',
                    textStyle: {
                        rich: {
                            B: {
                                backgroundColor: {
                                    image: require('../../../assets/visualization/noData.png'),
                                },
                                height: this.fontSize * 3,
                            },
                        }
                    },
                }
            }
        },
    }
};
</script>
<style lang="scss" scoped>
@import url('./public.css');

::v-deep .el-input__inner {
    background-color: transparent !important;
    border: 0;
    font-family: 'PingFangSt';
    color: #fff;
    font-size: 0.698vw;
    font-style: italic;
    text-shadow: 0 0 0.279vw #006affcc;
    padding: 0.116vw;
    font-weight: bold;
}

::v-deep .el-select__caret {
    color: #fff !important;
    font-size: 0.581vw !important;
}

::v-deep .el-input {
    display: flex;
    align-items: center;
}

#app {
    width: 100%;
    height: 100%;
    overflow: auto;

}

.fullScreen {
    position: fixed;
    top: 0.698vw;
    right: 3.047vw;
    color: #E3F3FF;
    z-index: 999;

    i {
        font-size: 0.419vw !important;
    }
}

#app>.main {
    width: 100vw;
    height: 30vw;
    // height: 119.4444vh;
    // width: 398.1481vh;
    background: url('../../../assets/visualization/IntelligentSecurity/backgroundAllL.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    padding: 1.628vw 3.721vw 0.581vw 3.721vw;
    position: relative;


    >div {
        width: 100%;
        height: 100%;
    }

    .center {
        width: calc(100% - 23.256vw - 23.256vw);

        >div {
            width: 100%;
            height: 100%;
        }


    }

    .earthMap {
        position: relative;
        height: 100%;
        margin-bottom: 0.521vw;


        .wordMapBox {
            width: 9.302vw !important;
            height: 1.279vw !important;
        }

        .wordMapBox>div {
            width: 3.721vw;
            height: 1.279vw;
            font-size: 0.512vw;
            line-height: 1.279vw;
        }
    }

    .left,
    .right {
        width: 23.256vw;

        .header {
            height: 1.395vw;
            display: flex;
            color: #CDD6e3;
            justify-content: center;


            .dateTime {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex: 1;

                span:first-child {
                    font-size: 0.419vw;
                }

                span:last-child {
                    font-size: 0.279vw;
                }
            }

            .statistics {
                width: 4.651vw;
                height: 1.395vw;
                margin-left: 2.326vw;
                display: flex;

                >div:first-child {
                    width: 1.395vw;
                    height: 1.395vw;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                >div:last-child {
                    width: calc(4.651vw - 1.395vw);
                    height: 1.395vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    span:first-child {
                        font-size: 0.326vw;
                        color: #E3F3FF;
                    }

                    span:last-child {
                        font-size: 0.651vw;
                        color: #01E3D8;

                        span {
                            font-size: 0.256vw;
                            color: #E3F3FF;
                        }
                    }
                }
            }

            .switchProject {
                height: 1.395vw;
                display: flex;
                align-items: center;
                margin-left: 4.977vw;
                flex: 1;
            }
        }

        .body {
            margin: 0.814vw 0;
            width: 100%;
            height: calc(100% - 1.395vw - 0.814vw - 0.814vw);
        }

        .leftBody {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;

            >div {
                width: 100%;
            }

            .left1 {
                height: 4.302vw;
                margin-bottom: 0.465vw;
            }

            .left2 {
                height: 7.442vw;
                margin-bottom: 0.465vw;
            }

            .left3,
            .left4 {
                height: 8.372vw;
                width: 11.395vw;
                margin-bottom: 0.465vw;
            }

            .left5,
            .left6 {
                height: 3.605vw;
                width: 11.395vw;
                margin-bottom: 0.465vw;
            }
        }

        .rightBody {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;

            >div {
                height: 5.814vw;
                width: 11.395vw;
            }
        }
    }

    .leftTop {
        display: flex;
        font-size: 0.326vw;
        padding-top: 0.233vw;
    }

    .leftTop>div>div:first-child {
        margin-bottom: 0.233vw;
    }

    .leftTop>div:nth-child(1) {
        width: 4.186vw;
        margin-right: 0.465vw;
    }

    .leftTop>div:nth-child(2) {
        width: 13.023vw;
        margin-right: 0.465vw;
    }

    .leftTop>div:nth-child(3) {
        width: 4.186vw;
    }

    .itemLLDB {
        display: flex;

        div {
            flex: 1;
            display: flex;
            justify-content: space-between;
        }

        span {
            color: #00aaff;
            margin: 0 0.233vw;
        }
    }

    .tableBox {
        display: flex;
        padding: 0 0.465vw;

        .tableBoxMain {
            width: 50%;
            height: 100%;

            .tableHeader {
                display: flex;
                border-bottom: 0.023vw solid #00aaff;
                height: 1.047vw;
                font-size: 0.372vw;

                span {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #00aaff;
                }

                >span:first-child {
                    justify-content: flex-start;
                }

                >span:last-child {
                    justify-content: flex-end;
                }
            }

            .tableBodyFather {
                width: 100%;
                height: calc(100% - 1.047vw);
                overflow: hidden;
            }

            .tableBody {
                height: 100%;
                width: 100%;
                font-size: 0.326vw;

                .tableTh {
                    display: flex;
                    height: 1.047vw;
                    font-size: 0.372vw;

                    >span {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #E3F3FF;
                    }

                    >span:first-child {
                        justify-content: flex-start;
                    }

                    >span:last-child {
                        justify-content: flex-end;
                    }

                    .indexIcon {
                        display: flex;
                        width: 0.465vw;
                        height: 0.465vw;
                        padding: 0.14vw 0.233vw;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        border: 1px solid #e4f1fa33;
                        background: radial-gradient(93.47% 77.22% at 0% 100%, #d6f3ffcc 0%, #a0d2ff3b 36.93%, #ffffff00 100%, #35435800 100%), #0085ffb3;
                        margin-right: 0.116vw;
                    }
                }
            }
        }

        .tableBoxMain:first-child {
            margin-right: 0.465vw;
        }
    }
}
</style>